import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-account-approval',
  templateUrl: './account-approval.component.html',
  styleUrls: ['./account-approval.component.css']
})
export class AccountApprovalComponent implements OnInit {
  accounts: any[] = []; rpt: any; hide = false; merchants:any[]=[];
  constructor(private use: NavComponent, private _api: ManagerService, private exp: ExportExcelService, private api: ApiService) { }

  ngOnInit() {
    this.rpt = { id: this.use.active.merchantid, type: '', start: '', end: '', code: 0 }
    this.getAllAccounts();
    this.getMerchant();
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=100').subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getAllAccounts() {
    this._api.getAccountsByStatus('pending').subscribe((data: any) => {
      this.accounts = data;
      console.log(data);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  generate() {
    this.api.post('accounts/report',this.rpt).subscribe((data: any) => {
      this.accounts = data; this.hide=true;
      console.log(data);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }
  
  confirm(val) {
    val.status = "Active"; val.mdate = new Date(); // val.muserid = this.use.active.id;
    this._api.putAccount(val.accountid,val).subscribe((data: any) => {
      this.getAllAccounts();
      this.use.showMsg('success', 'Account Approved', 'Account Has been Activated');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  Export() {
    this.Sort().then((data)=>{
      // return data;
      this.exp.exportAsExcelFile(data, 'new-account');
    });
    // this._exp.exportAsExcelFile(this.filteredAccount, 'account');
  }

  async Sort() {
    const accounts = [];
    await this.accounts.forEach((c)=>{
      const acct = { Membernumber: c.customer.code, FullName: c.customer.fullname, SchemeNumber: c.code, Schemetype: c.type, Contribution: c.balance, Savings: c.savings, Retirment: c.retirement, Source: c.userid, Referer: c.muserid != null && c.muserid.length < 8 ? c.muserid : "", CreationTime: c.date };
        accounts.push(acct);
    });
    return accounts;
  }

}
