import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { ApiService } from 'src/app/services/api.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';

@Component({
  selector: 'app-account-autodebit',
  templateUrl: './account-autodebit.component.html',
  styleUrls: ['./account-autodebit.component.css']
})
export class AccountAutodebitComponent implements OnInit {

  accounts: any[] = []; autodebit:any[]=[]; response:any; search: any='';
  auto: any = { page: 1, limit: 10 }
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10);
    // this.filteredAccount = this.serachResult(value);
  }
  constructor(private use: NavComponent, private api: ApiService, private exp: ExportExcelService) { }

  ngOnInit() {
    this.getAllAccounts();
  }

  getAllAccounts() {
    this.api.get('autodebit').subscribe((data: any) => {
      this.accounts = data.data; this.response = data;
      // console.log(data.data);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'AutoDebit List Fail', 'Unable to Fetch AutoDebit List');
    });
  }

  
  getMore(page,limit) {
    // console.info(this.searchTitle);
    this.api.get('autodebit?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.accounts = data.data; this.response = data; //console.log(data);
    },);
  }

  Start() {
    this.api.get('services/today?limit=5').subscribe((data: any) => {
      this.use.showMsg('success', 'AutoDebit Request Sent', 'AutoDebit Has been Initiated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'AutoDebit Request Fail', 'Unable to Sent AutoDebit');
    });
  }
  
  pushDebit(val) {
    this.api.get('services/payment/'+val.autodebitid).subscribe((data: any) => {
      this.use.showMsg('success', 'AutoDebit Request Sent', 'AutoDebit Has been Initiated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'AutoDebit Request Fail', 'Unable to Sent AutoDebit');
    });
  }

  disabled(val,status) {
    val.active = status; val.mdate = new Date();
    this.api.put('autodebit/'+val.autodebitid,val).subscribe((data: any) => {
      this.use.showMsg('success', 'AutoDebit Disabled', 'AutoDebit Has been DeActivated');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'AutoDebit Disabling Fail', 'Unable to Disable AutoDebit');
    });
  }
  
  Export(page,limit) {
    this.api.get('autodebit?page='+page+'&limit='+limit).subscribe((data: any)=>{
      this.autodebit = data.data;
      this.Sort().then((data)=>{
        // return data;
        this.exp.exportAsExcelFile(data, 'autodebit');
      });
    },);
  }

  async Sort() {
    const accounts = [];
    await this.autodebit.forEach((c)=>{
      const acct = { Code: c.code, Schemenumber: c.account.code, Mobile: c.mobile, Network: c.network, Amount: c.amount, Frequency: c.frequency, Firstpaymentdate: c.firstpaymentdate, Lastpaymentdate: c.lastpaymentdate, Nextpaymentdate: c.nextpaymentdate, Status: c.active == true? 'Active': 'Disabled', CreationTime: c.date };
        accounts.push(acct);
    });
    return accounts;
  }

}




