import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-migrations',
  templateUrl: './migrations.component.html',
  styleUrls: ['./migrations.component.css']
})
export class MigrationsComponent implements OnInit {
  merchants: any[] = []; upload: any[] = []; info: any; exps: any[] = []; id:any; user: any; 
  types: any[] = ["Customer","Accounts","Agent","Officer","AutoDebit"]
  constructor(private api: ApiService, private exp: ExportExcelService, private use: NavComponent) { }

  ngOnInit() {
    // this.user = this.use.active;
    this.init();
    this.getMore();
  }
  
  getMore(limit=50) {
    this.api.get('merchant?limit='+limit).subscribe((data: any) => {
      this.merchants = data.data; //this.response = data; // console.log(data);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Merchant List Fail', 'Unable to Fetch Customer Details');
    });
  }

  getCutomer(mobile) {
    this.api.get('ussd/getCustomer/PPT/178116723/'+mobile).subscribe((data: any) => {
      console.log(data);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Merchant List Fail', 'Unable to Fetch Merchant List');
    });
  }

  readFile(event) {
    // this.img = event.target.files[0];
    if(event.target.files[0]) {
      this.exp.readExcel(event.target.files[0]);
      setTimeout(() => { 
        // this.upload = this.exp.import
        this.upload = this.formatRead(this.exp.import); 
        console.info(this.upload);
      }, 3000);
    }else {
      this.use.showMsg('error', 'File Selection', 'Please Select a valid Excel file');
    }
  }

  downloadData() {
    this.api.getdata(this.info.url).subscribe((data: any)=> {
      this.exp.exportAsExcelFile(data.result, this.info.name || 'data'); this.upload = data.result;
      this.use.showMsg('success', 'File Downloaded', 'Succesfully Download File');
    });
  }

  uploadData() {
    this.api.post(this.info.url, this.upload).subscribe((data:any)=> {
      // this.exp.exportAsExcelFile(data, 'data');
      this.use.showMsg('success', 'File Uploaded', 'Succesfully Upload File');
    });
  }

  formatRead(values) {
    const id = 1; this.exps = []; var time = 0;
    values.forEach((e: any) => {
      const ed = e;
      ed.merchantid = this.info.merchantid;
      ed.userid = this.use.active.id;
      // // ed.maritalstatus = "N/A";
      // ed.firstpaymentdate = e.fdate != null? new Date((e.fdate - (25567 + 1))*86400*1000).toDateString(): new Date(),
      // ed.lastpaymentdate = e.ldate != null? new Date((e.ldate - (25567 + 1))*86400*1000).toDateString(): new Date(),
      // // ed.date = ed.date != null? new Date((ed.date - (25567 + 1))*86400*1000).toDateString(): new Date(),
      // ed.active = true;
      // // console.log(ed.mobile);
      // ed.mobile = '+233' + ed.mobile.substr(1); // ed.mobile.substring()
      // console.log(ed.mobile);
      // ed.phonenumber = '+233' + ed.phonenumber.substr(1); // ed.mobile.substring()
      // ed.accounts = ed.schemenumber
      // ed.purpose = "N/A";
      // ed.date = new Date();
      // const ed = {
      //   CODE: e.CODE || null, FULLNAME: e.FULLNAME, MOBILE: e.MOBILE,
      //   EMAIL: e.EMAIL, DATEOFBIRTH: e.DATEOFBIRTH, 
      //   GENDER: e.GENDER, OCCUPATION: e.OCCUPATION, MARITAL: e.MARITAL, WEDDINGDATE: e.WEDDINGDATE,
      //   GROUP: e.GROUP, DEPARTMENT: e.DEPARTMENT, NOTE: e.NOTE || null, // +', '+e.EMERGENCY_NAME+':- '+ e.EMERGENCY_CONTACT,
      //   Address: e.ADDRESS, STATUS: e.STATUS, DATE: e.JOINDATE
      // };
      this.exps.push(ed); this.id++;
      // this.getCutomer(ed.phonenumber);
      // time += 500;
      // setTimeout(() => { 
      //   this.getCutomer(ed.phonenumber);
      // }, time);
    });
    return this.exps;
  }

  init() {
    this.info = { url: '', name: '', merchantid: this.use.active.merchantid };
  }

}
