import { Component, OnInit } from '@angular/core';
import { Router,  ActivatedRoute} from '@angular/router';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-view-group',
  templateUrl: './view-group.component.html',
  styleUrls: ['./view-group.component.css']
})
export class ViewGroupComponent implements OnInit {

  recipients: any; accnt: any; id: string; rpt: any; autodebit: any;
  add: boolean; accounts: any[] = []; trans: any[] = []; response: any; search: string = '';
  type: any[] = [ "ALL","DEPOSIT", "WITHDRAWAL", "PENSION", "LOAN", "SAVINGS"];
  
  private _searhcTitle: string;
  
  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
  }
  
  
  constructor(
    private api: ApiService, 
    private _use: NavComponent, 
    private _api: ManagerService, 
    private rout: Router, 
    private router: ActivatedRoute,
    private _exp: ExportExcelService) { }

    

  ngOnInit() {
    this.id = this.router.snapshot.paramMap.get('id');
    this.getAllGroups(this.id);
    // this.getAllGroupAccount(this.id);
    this.rpt = { id: '', type: '', start: '', end: '', code: 0 }
  }

  getAllGroups(id: any) {
    this.api.get("icare/view/"+id).subscribe((data: any) => {
      this.recipients = data.recipients; this.accounts = data.accounts;
      this.trans = data.payments;
      this.autodebit = data.autodebit
      
    });
  }

  getAllGroupAccount(id: any) {
    this._api.getGroupAccount(id).subscribe((data: any) => {
      this.accnt = data; 
      console.log(data);
    });
  }

  getAccountReport() {
    this.api.post("accounts/statement/",this.rpt).subscribe((data: any) => {
      this.autodebit = data;
    });
  }


}
